<template>
  <div>
    <v-snackbar
      v-model="showSnackbar"
      :timeout="snackTimeout"
      :color="snackType === 'sucess' ? 'green' : 'red'"
      content-class="text-center"
      transition="scroll-x-transition"
    >
      <strong>{{ snackText }}</strong>
      <template #action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click.native="closeSnack">
          <v-icon medium color="white"> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ['showSnack', 'snackText', 'snackType', 'snackTimeout'],
  data: () => ({
    name: 'SnackbarMessage',
    showSnackbar: false
  }),

  watch: {
    showSnack() {
      return this.showSnack ? this.initialize(this.showSnack) : ''
    },

    showSnackbar() {
      return this.showSnackbar ? true : this.changeSnack()
    },

    snackTimeout() {
      return this.snackTimeout ? this.snackTimeout : '4000'
    }
  },

  methods: {
    initialize(showSnackValue) {
      this.showSnackbar = showSnackValue
    },

    closeSnack() {
      this.showSnackbar = false
    },

    changeSnack() {
      let vShowSnack = this.showSnack
      vShowSnack = false
      this.$emit('update:showSnack', vShowSnack)
    }
  }
}
</script>

<style>
.v-snack__wrapper {
  min-width: 150px;
}
</style>
